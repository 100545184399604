{
  "name": "nodecg",
  "version": "2.5.0",
  "description": "Dynamic broadcast graphics rendered in a browser",
  "keywords": [
    "graphics",
    "nodecg",
    "node",
    "dynamic",
    "broadcast"
  ],
  "homepage": "https://nodecg.dev/",
  "bugs": "https://github.com/nodecg/nodecg/issues",
  "repository": {
    "type": "git",
    "url": "https://github.com/nodecg/nodecg.git"
  },
  "license": "MIT",
  "exports": {
    ".": "./index.js",
    "./types": {
      "types": "./types/index.d.ts"
    },
    "./types/augment-window": {
      "types": "./types/augment-window.d.ts"
    }
  },
  "bin": {
    "nodecg": "out/cli.mjs"
  },
  "files": [
    "dist",
    "out",
    "bundles/.empty_directory",
    "db/.empty_directory",
    "schemas",
    "types",
    "index.js"
  ],
  "scripts": {
    "start": "node index.js",
    "dev": "del-cli dist out && run-p dev:*",
    "dev:client": "npm run build:client -- --watch",
    "dev:tsc": "tsc -b src/server src/client test --watch --preserveWatchOutput",
    "dev:copy-templates": "onchange \"src/server/**/*.tmpl\" -i -- cpy \"src/server/**/*.tmpl\" out/server",
    "dev:cli": "npm run build:cli -- --watch",
    "test": "vitest",
    "typetest": "cd typetest/fake-bundle && npm run build",
    "lint": "npm-run-all -s lint:*",
    "lint:prettier": "prettier --check .",
    "lint:eslint": "eslint --cache",
    "fix": "npm-run-all -s fix:*",
    "fix:prettier": "prettier --write .",
    "fix:eslint": "npm run lint:eslint -- --fix ",
    "build": "del-cli dist out && run-s build:*",
    "build:tsc": "tsc -b src/server src/client src/cli",
    "build:client": "tsx scripts/build-client.ts",
    "build:copy-templates": "cpy \"src/server/**/*.tmpl\" out/server",
    "build:cli": "esbuild src/cli/bin/nodecg.ts --bundle --format=esm --sourcemap --platform=node --target=node18 --outfile=out/cli.mjs --packages=external",
    "generate-migrations": "cd src/server && typeorm-ts-node-commonjs migration:generate ./database/migration/rename-me -d ./database/datasource.ts"
  },
  "dependencies": {
    "@inquirer/prompts": "^7.2.1",
    "@nodecg/json-schema-defaults": "^1.0.4",
    "@nodecg/json-schema-lib": "0.1.0",
    "@polymer/app-layout": "^3.1.0",
    "@polymer/app-route": "^3.0.2",
    "@polymer/iron-collapse": "^3.0.1",
    "@polymer/iron-flex-layout": "^3.0.1",
    "@polymer/iron-icons": "^3.0.1",
    "@polymer/iron-image": "^3.0.2",
    "@polymer/iron-localstorage": "^3.0.1",
    "@polymer/iron-pages": "^3.0.1",
    "@polymer/iron-selector": "^3.0.1",
    "@polymer/paper-button": "^3.0.1",
    "@polymer/paper-card": "^3.0.1",
    "@polymer/paper-dialog": "^3.0.1",
    "@polymer/paper-dialog-behavior": "^3.0.1",
    "@polymer/paper-dialog-scrollable": "^3.0.1",
    "@polymer/paper-icon-button": "^3.0.2",
    "@polymer/paper-item": "^3.0.1",
    "@polymer/paper-slider": "^3.0.1",
    "@polymer/paper-spinner": "^3.0.2",
    "@polymer/paper-styles": "^3.0.1",
    "@polymer/paper-tabs": "^3.1.0",
    "@polymer/paper-toast": "^3.0.1",
    "@polymer/paper-toolbar": "^3.0.1",
    "@polymer/polymer": "^3.5.1",
    "@sentry/browser": "^7.91.0",
    "@sentry/node": "^7.91.0",
    "@types/cookie-parser": "^1.4.6",
    "@types/express-session": "^1.17.10",
    "@types/multer": "^1.4.11",
    "@types/node": "18",
    "@types/passport": "^1.0.16",
    "@types/soundjs": "^0.6.31",
    "@vaadin/vaadin-upload": "^4.4.3",
    "@webcomponents/webcomponentsjs": "^2.8.0",
    "ajv": "^8.17.1",
    "ajv-draft-04": "^1.0.0",
    "ajv-formats": "^2.1.1",
    "app-root-path": "^3.1.0",
    "better-sqlite3": "^9.6.0",
    "body-parser": "^1.20.2",
    "chalk": "^5.4.1",
    "cheerio": "1.0.0-rc.12",
    "chokidar": "^4.0.3",
    "clipboard": "^2.0.11",
    "commander": "^13.0.0",
    "compression": "^1.7.4",
    "cookie-parser": "^1.4.6",
    "cookies-js": "^1.2.3",
    "cosmiconfig": "^8.3.6",
    "draggabilly": "^2.4.1",
    "events": "^3.3.0",
    "express": "^4.18.2",
    "express-session": "^1.17.3",
    "express-transform-bare-module-specifiers": "^1.0.4",
    "extend": "^3.0.2",
    "fast-equals": "^5.0.1",
    "fast-memoize": "^2.5.2",
    "fp-ts": "^2.16.1",
    "git-rev-sync": "^3.0.2",
    "hasha": "^5.2.2",
    "hosted-git-info": "^8.0.2",
    "httpolyglot": "^0.1.2",
    "iframe-resizer": "^4.3.9",
    "io-ts": "^2.2.21",
    "is-error": "^2.2.2",
    "json-ptr": "^3.1.1",
    "json-schema-to-typescript": "^15.0.3",
    "klona": "^2.0.6",
    "lodash": "^4.17.21",
    "multer": "^1.4.5-lts.1",
    "nano-spawn": "^0.2.0",
    "npm-package-arg": "^12.0.1",
    "object-path": "^0.11.8",
    "packery": "^2.1.2",
    "passport": "^0.6.0",
    "passport-discord": "^0.1.4",
    "passport-local": "^1.0.0",
    "passport-steam": "^1.0.18",
    "passport-twitch-helix": "^1.1.0",
    "process": "^0.11.10",
    "semver": "^7.6.3",
    "serialize-error": "^8.1.0",
    "session-file-store": "^1.5.0",
    "socket.io": "^4.8.1",
    "socket.io-client": "^4.8.1",
    "soundjs": "^1.0.1",
    "tar": "^7.4.3",
    "ts-essentials": "^9.4.1",
    "tslib": "^2.6.2",
    "typeorm": "0.3.19",
    "util": "^0.12.5",
    "uuid": "^9.0.1",
    "winston": "^3.11.0",
    "yargs": "^15.4.1",
    "zod": "^3.22.4"
  },
  "devDependencies": {
    "@eslint/js": "^9.17.0",
    "@types/app-root-path": "^1.2.8",
    "@types/clone": "^2.1.4",
    "@types/compression": "^1.7.5",
    "@types/deep-equal": "^1.0.4",
    "@types/express": "^4.17.21",
    "@types/extend": "^3.0.4",
    "@types/git-rev-sync": "^2.0.2",
    "@types/glob": "^8.1.0",
    "@types/hosted-git-info": "^3.0.5",
    "@types/is-ci": "^3.0.4",
    "@types/lodash": "^4.17.13",
    "@types/npm-package-arg": "^6.1.4",
    "@types/object-path": "^0.11.4",
    "@types/passport-discord": "^0.1.14",
    "@types/passport-local": "^1.0.38",
    "@types/passport-steam": "^1.0.5",
    "@types/semver": "^7.5.8",
    "@types/session-file-store": "^1.2.5",
    "@types/sinon": "^10.0.20",
    "@types/uuid": "^8.3.4",
    "@types/yargs": "^15.0.19",
    "cpy-cli": "^5.0.0",
    "cross-env": "^7.0.3",
    "del-cli": "^5.1.0",
    "esbuild": "^0.19.12",
    "esbuild-plugin-copy": "^2.1.1",
    "eslint": "^9.17.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "is-builtin-module": "^3.2.1",
    "is-ci": "^3.0.1",
    "is-windows": "^1.0.2",
    "npm-run-all2": "^7.0.2",
    "onchange": "^7.1.0",
    "prettier": "^3.4.2",
    "puppeteer": "^23.11.1",
    "replace-in-file": "^6.3.5",
    "simple-git": "^3.22.0",
    "sinon": "^14.0.2",
    "ts-node": "^10.9.2",
    "tsx": "^4.19.2",
    "type-fest": "^4.31.0",
    "typescript": "~5.7.2",
    "typescript-eslint": "^8.18.2",
    "vite-plugin-swc-transform": "^1.0.1",
    "vitest": "^2.1.8",
    "vitest-mock-express": "^2.2.0"
  },
  "engines": {
    "node": "^18.12.0 || ^20.9.0 || ^22.11.0"
  }
}
